
import Head from "next/head";
import { jsonLdScriptProps } from "react-schemaorg";
import { Recipe } from "schema-dts";
import { cleanHtmlTags, getMeta } from '../../lib/utils/utils';
import React from "react";
import { StructuredDataProps } from './structured-data'
import { useRouter } from "next/router";

export const StructuredDataRecipe = ({ content }: StructuredDataProps) => {

    function cleanText(text) {
        return cleanHtmlTags(text).replace(/\s+/g, " ").trim();
    }

    const { asPath } = useRouter();
    process.env.NEXT_PUBLIC_BASE_URL
    return (
        <Head>
            <script
                {...jsonLdScriptProps<Recipe>({
                    "@context": "https://schema.org",
                    "@type": "Recipe",
                    name: content.title,
                    image: content.field_image.field_media_image.uri?.url,
                    description: getMeta(content.metatag, 'description'),
                    keywords: getMeta(content.metatag, 'keywords'),
                    author: {
                        "@type": "Organization",
                        name: "Navidul",
                        url: process.env.NEXT_PUBLIC_BASE_URL + asPath,
                    },
                    datePublished: content.created,
                    prepTime: `PT${content.field_reading_time ?? 0}M`,
                    cookTime: `PT${content.field_cooking_time ?? 0}M`,
                    totalTime: `PT${(content.field_cooking_time ?? 0) + (content.field_reading_time ?? 0)}M`,
                    recipeIngredient: content.field_ingredients.map((value) => (
                        cleanText(value.processed)
                    )),
                    recipeInstructions: content.field_preparation.map((value) => (
                        {
                            "@type": "HowToStep",
                            text: cleanText(value.processed)
                        }
                    )),
                })}
            />
        </Head>
    )
};
