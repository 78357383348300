import { FunctionComponent, useEffect, useState } from 'react';
import { oneLine } from 'common-tags';

import { Facebook, Twitter, WhatsApp } from '@/icons';
import { UnderlineTag } from '@/atoms';
import { Prose, RecipeFeatures, SocialMedia } from '@/molecules';

import { TypeRecipeArticleProps } from './types';

export const RecipeArticle: FunctionComponent<TypeRecipeArticleProps> = ({
  className = '',
  title,
  date,
  time,
  servings,
  difficulty,
  initialText,
  ingredients,
  steps,
  finalText,
  timeToRead,
  displayBullets,
  id
}: TypeRecipeArticleProps) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(document.URL);
  }, []);

  return (
    <div
      className={oneLine`
      flex flex-col gap-6 md:gap-12 
      ${className}
    `}
      id={id}
    >
      <UnderlineTag position="left" theme="light">
        Recetas
      </UnderlineTag>

      <h1 className="text-headline text-headline--h2">{title}</h1>

      <div className="flex flex-col justify-between gap-4 md:flex-row text-body text-body--m">
        <div className='flex flex-col gap-4'>
          <span>{date}</span>
          {timeToRead && (
            <span className="text-text-secondary">{timeToRead} min de lectura</span>
          )}
        </div>
        <RecipeFeatures difficulty={difficulty} servings={servings} time={time} />
      </div>
      <SocialMedia
        title="COMPÁRTELO"
        socialMedias={[
          {
            Icon: Facebook,
            href: `https://www.facebook.com/sharer.php?u=${url}`,
          },
          {
            Icon: Twitter,
            href: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
          },
          {
            Icon: WhatsApp,
            href: `https://api.whatsapp.com/send?text=${title}%20${url}`,
          },
        ]}
        isColumn
        variant="gray"
        position="start"
      />

      <Prose html={initialText} className="text-[large] md:text-base md:leading-[28px]" />

      <div className='prose text-[large] md:text-base md:leading-[28px] text-black'>
        <h2><strong>Ingredientes:</strong></h2>
        <ul>
          {ingredients.map((value, index) => (
            <li key={`ingredients-${index}`}>
              <Prose className='text-[large] md:text-base md:leading-[28px] mt-0 mb-0' html={value} />
            </li>
          ))}
        </ul>
      </div>
      <div className='prose text-[large] md:text-base md:leading-[28px] text-black max-w-full'>
        <h2><strong>Elaboración:</strong></h2>
        {
          displayBullets ?
            <ol>
              {
                steps.map((value, index) => (
                  <li key={`preparation-${index}`}><Prose html={value} className="text-[large] md:text-base md:leading-[28px] mt-0 mb-0" /></li>
                ))
              }
            </ol>
            :
            <>
              {
                steps.map((value, index) => (
                  <Prose html={value} key={`preparation-${index}`} className="text-[large] md:text-base md:leading-[28px] mt-0 mb-0" />
                ))
              }
            </>
        }
      </div>

      <Prose html={finalText} className="text-[large] md:text-base md:leading-[28px]" />

      <hr className="text-secondary-200" />

      <SocialMedia
        title="COMPÁRTELO"
        socialMedias={[
          {
            Icon: Facebook,
            href: `https://www.facebook.com/sharer.php?u=${url}`,
          },
          {
            Icon: Twitter,
            href: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
          },
          {
            Icon: WhatsApp,
            href: `https://api.whatsapp.com/send?text=${title}%20${url}`,
          },
        ]}
        isColumn
        variant="gray"
        position="start"
      />

    </div >
  );
};

RecipeArticle.displayName = 'RecipeArticle';
