import { DrupalBlock, DrupalNode, DrupalParagraph } from "next-drupal";
import { LandingPage } from "components/content/landing-page";
import { LegalPage } from "components/content/legal-page";
import { Article } from "components/content/article";
import { ArticleCategory } from "components/content/article-category";
import { Product } from "components/content/product";
import { Recipe } from "components/content/recipe";

const contentTypes = {
    "node--landing_page": LandingPage,
    "node--legal_page": LegalPage,
    "node--article": Article,
    "node--recipe": Recipe,
    "taxonomy_term--article_category": ArticleCategory,
    "shopify_product--shopify_product": Product,
};

export interface NodeProps {
    showPopUp?: boolean
    content: {
        node: DrupalNode,
        paragraphs: DrupalParagraph[],
        views?: {
            articles: DrupalNode[],
            products: DrupalNode[],
            recipes: DrupalNode[],
        }
        type: string,
        blocks: DrupalBlock[],
        theme: boolean,
        preFooter: boolean,
    },
    children?: React.ReactNode
}

export function Node({ content, ...props }: NodeProps) {
    const Component = contentTypes[content?.type];

    if (!content || !Component) {
        return null;
    }

    return <Component content={content} {...props} />;
}