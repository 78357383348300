
import Head from "next/head";
import { jsonLdScriptProps } from "react-schemaorg";
import { BlogPosting } from "schema-dts";
import { cleanHtmlTags } from '../../lib/utils/utils';
import React from "react";
import { StructuredDataProps } from './structured-data'
import { useRouter } from "next/router";

export const StructuredDataBlog = ({ content }: StructuredDataProps) => {

    function cleanText(text) {
        return cleanHtmlTags(text).replace(/\s+/g, " ").trim();
    }

    const { asPath } = useRouter();

    return (
        <Head>
            <script
                {...jsonLdScriptProps<BlogPosting>({
                    "@context": "https://schema.org",
                    "@type": "BlogPosting",
                    mainEntityOfPage: {
                        "@type": "WebPage",
                        "@id": process.env.NEXT_PUBLIC_BASE_URL + asPath,
                    },
                    headline: content.title,
                    description: content.field_summary ? cleanText(content.field_summary.processed) : null,
                    image: content.field_image.field_media_image.uri?.url,
                    author: {
                        "@type": "Organization",
                        name: "Navidul",
                        url: process.env.NEXT_PUBLIC_BASE_URL,
                    },
                    publisher: {
                        "@type": "Organization",
                        name: "Navidul",
                        logo: {
                            "@type": "ImageObject",
                            url: "https://admin.navidul.es/sites/default/files/2023-08/logo.webp",
                        }
                    },
                    datePublished: content.created
                })}
            />
        </Head>
    );

}
