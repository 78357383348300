import { Container } from '@/atoms';
import { RecipeArticle } from '@/templates';
import { getUrlFromMedia } from "@/utils/utils";
import { NodeProps } from "components/node";
import { StructuredData } from 'components/structured-data/structured-data';

export function Recipe({ content }: NodeProps) {
    const date = content.node.changed
        ? new Date(content.node.changed).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        : new Date().toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    return (
        <>
            <StructuredData content={content.node} />
            <Container
                backgroundImage={getUrlFromMedia(content.node.field_image ?? '/')}
                className="relative h-[320px] md:h-[500px]"
                overlay={content.node.field_dark_banner === true ? 0.5 : 0}
            >
                <div className="bg-white h-[calc(100px_-_16px)] md:h-[calc(180px_-_32px)] bottom-0 right-0 left-1/2 absolute" />
            </Container>

            <Container
                wrapper
                wrapperSize="max-w-[790px]"
                padding="px-2 py-4 md:py-8"
                backgroundColor="bg-white"
            >
                <RecipeArticle
                    title={content.node.title}
                    date={date}
                    initialText={content.node.field_initial_text}
                    ingredients={content.node.field_ingredients}
                    steps={content.node.field_preparation}
                    finalText={content.node.field_final_text}
                    time={content.node.field_cooking_time}
                    servings={content.node.field_servings}
                    difficulty={content.node.field_difficulty}
                    timeToRead={content.node.field_reading_time}
                    displayBullets={content.node.field_display_bullets}
                    className="bg-white -mt-[100px] md:-mt-[180px] pt-6 px-4 md:pt-10 md:px-16"
                    id='article'
                />
            </Container>
        </>
    )
}
