import { NodeProps } from "components/node";
import { Fragment, useEffect } from 'react';
import { BackgroundImage, Container, IconText, Tag, Text } from '@/atoms';
import {
  AboutProduct,
  ContentImage,
  NutritionInfoTable,
  ProductDescription,
  ProductGallery,
  TabGroup,
  TextinputButton,
  TwoColumn,
} from '@/molecules';
import { Table, TraceabilityWithModule } from '@/organisms';
import { ProductBlockShopify } from '@/templates';

import { Variation } from '@/molecules/card-product/types';

import Background from '@/assets/images/background-product-page.png';
import { Form, Formik } from 'formik';
import { gtmPush } from '@/utils/gtm';
import { ordenarEtiquetas } from '@/utils';
import { ParseDrupalIcon } from '@/utils/parseDrupalIcon';
import { ParseText } from '@/utils/parseText';
import React from "react";
import { StructuredData } from "../structured-data/structured-data";
import { useRouter } from "next/router";

/**
 * Product
 */
export function Product({ content }: NodeProps) {

  const { asPath } = useRouter();
  useEffect(() => {
    const etiquetas = content.node ? content.node.tags.map((item) => (item.name)) : [];
    gtmPush({ ecommerce: null });
    gtmPush({
      event: 'view_item',
      ecommerce: {
        value: content.node.variants[0].price ?? 0,
        currency: 'EUR',
        items: [
          {
            item_id: content.node.product_id ?? '',
            item_name: content.node.title.toUpperCase() ?? '',
            affiliation: 'Navidul',
            item_brand: 'Navidul',
            item_category: ordenarEtiquetas(etiquetas)[0][0]
              ? ordenarEtiquetas(etiquetas)[0][0]
              : '',
            item_category2: ordenarEtiquetas(etiquetas)[0][1]
              ? ordenarEtiquetas(etiquetas)[0][1]
              : '',
            item_category3: ordenarEtiquetas(etiquetas)[0][2]
              ? ordenarEtiquetas(etiquetas)[0][2]
              : '',
            price: content.node.variants[0].price ?? 0,
            quantity: 1,
            discount:
              content.node.variants[0].compare_at_price &&
                content.node.variants[0].price
                ? content.node.variants[0].compare_at_price -
                content.node.variants[0].price
                : 0,
          },
        ],
      },
    });
  }, [asPath]);

  const advantages = content.blocks[0]?.field_icon_items.length > 0 ? content.blocks[0].field_icon_items.map((icon, index) => (
    <IconText text={icon.field_plain_text} key={index}>
      {ParseDrupalIcon({ icon: icon.field_icon, width: "24", height: "24" })}
    </IconText>
  )) : undefined;

  const about =
    content.node.field_about
      ?.map((item: any, index: number) => (
        <React.Fragment key={index}>
          {item.field_image ?
            <ContentImage
              image={{
                src: item.field_image?.field_media_image.uri.url,
                alt: item.field_image?.field_media_image.resourceIdObjMeta.alt,
              }}
              title={item.field_title}
              description={item.field_text}
              reverse={item.field_right_aligned}
            />
            :
            <AboutProduct>
              <AboutProduct.Text
                key={index}
                {...{
                  title: item.field_title,
                  children: ParseText({ html: item.field_text }),
                }}
              /></AboutProduct>}
        </React.Fragment>
      ))


  const nutrition = content.node.field_nutritional_information?.map(
    (table: any, index: number) => {
      const children: any = [];

      table.field_rows.map((itm: any, index: number) =>
        children.push(
          <Table.Line
            name={itm.field_attribute}
            value={itm.field_value}
            key={`${index}_${itm.type}`}
          />
        )
      );

      return (
        <Fragment key={index}>
          <NutritionInfoTable.Info title={table.field_description_title}>
            <Text className="text-body text-body--s">{table.field_description}</Text>
          </NutritionInfoTable.Info>

          <NutritionInfoTable.Info title={table.field_table_title}>
            <Table>{children}</Table>
          </NutritionInfoTable.Info>
        </Fragment>
      );
    }
  );

  const preservation =
    content.node.field_preservation
      ?.map((item: any, index: number) => (
        <React.Fragment key={index}>
          {item.field_image ?
            <Container wrapper>
              <ContentImage
                image={{
                  src: item.field_image?.field_media_image.uri.url,
                  alt: item.field_image?.field_media_image.resourceIdObjMeta.alt,
                }}
                title={item.field_title}
                description={item.field_text}
                className="items-center"
              />
            </Container>

            :
            <AboutProduct.Text
              key={index}
              {...{
                title: item.field_title,
                children: ParseText({ html: item.field_text }),
              }}
            />
          }
        </React.Fragment>

      ))

  const trazabilidad =
    content.node.field_traceability ?
      <TwoColumn gap="gap-8">
        <TwoColumn.Left className="aspect-square md:w-full">
          <Container wrapper backgroundColor="bg-primary-50">
            <Formik
              initialValues={{
                traceability: '',
              }}
              onSubmit={(data) => {
                gtmPush({
                  event: 'trazabilidad_producto',
                  codigo_producto: data.traceability,
                });
                setTimeout(
                  () =>
                    window.open(
                      `/es/trazabilidad-jamon?bridle=${data.traceability}`
                    ),
                  200
                );
              }}
            >
              <Form>
                <TraceabilityWithModule
                  backgroundColor="bg-primary-50"
                  backgroundColor2="bg-bg-quinary"
                  description={content.node.field_traceability.field_text}
                  padding="pt-[0px] px-[16px] pb-[40px] md:px-[72px] md:pb-[10px]"
                  title={content.node.field_traceability.field_title}
                  colorText="light"
                >
                  <TextinputButton
                    name="traceability"
                    placeholder="Pulsar"
                  />
                </TraceabilityWithModule>
              </Form>
            </Formik>
          </Container>
        </TwoColumn.Left>

        <TwoColumn.Right className="flex flex-col items-start empty:hidden">
          {content.node.field_traceability.field_properties?.map((item: any, index: number) => (
            <div key={index}>
              {index > 0 && (
                <hr className="text-secondary-200 mt-5 mb-5" />
              )}
              <h3 className="text-body font-bold  mb-6">
                {item.field_title}
              </h3>
              <p className="text-body text-body--m md:text-body md:text-body--l">
                {item.field_plain_text}
              </p>
            </div>
          ))}
        </TwoColumn.Right>
      </TwoColumn>
      : undefined;
  const tabsHeaders = [];
  about && tabsHeaders.push('Sobre el producto');
  content.node.field_nutritional_information.length !== 0 &&
    tabsHeaders.push('Información nutricional');
  trazabilidad && tabsHeaders.push('Trazabilidad');
  preservation && tabsHeaders.push('Conservación');
  return (
    <>
      <StructuredData content={content.node} />
      <TwoColumn className="md:!grid-cols-1 lg:!grid-cols-[1fr_1fr]">
        <TwoColumn.Left className="bg-primary-50 pt-[calc(var(--header-height)_+_20px)] pb-4 lg:pb-16 relative">
          <BackgroundImage backgroundImage={Background.src} />
          <Container padding="px-4 lg:pr-0">

            <div className="lg:max-w-[calc(var(--wrapper-size)/2)] w-full justify-center lg:self-end lg:pr-20">
              {content.node.variants[0].inventory_quantity < 1 && (
                <Tag colors="promo">Agotado</Tag>
              )}
              <ProductGallery
                slides={
                  content.node.extra_images.map((item: any) => ({
                    src: item.uri.url,
                  })) ?? []
                }
                className="w-full max-w-[275px] lg:max-w-none mx-auto"
              />
            </div>
          </Container>
        </TwoColumn.Left>

        <TwoColumn.Right className="bg-white pt-10 lg:pt-[calc(var(--header-height)_+_20px)] lg:pb-16">
          <Container padding="px-4 lg:pl-0">
            <div className="lg:max-w-[calc(var(--wrapper-size)/2)] w-full self-start lg:pl-20">
              <ProductDescription
                title={content.node.title} //provisional.
                description={content.node.body_html}
                moreInfo={content.node.field_weight}
                link={undefined}
                {...(advantages && { advantages })}
                productId={content.node.product_id}
                inStock={content.node.variants[0].inventory_quantity > 0}
                list={{
                  title: content.node.field_perks_title,
                  items: content.node.field_perks
                }}
                product={content.node}
                price={{
                  regular:
                    content.node.variants[0].compare_at_price ||
                    content.node.variants[0].price,
                  ...(content.node.variants[0].compare_at_price && {
                    sale: content.node.variants[0].price,
                  }),
                }}
              />
            </div>
          </Container>
        </TwoColumn.Right>
      </TwoColumn>
      <Container wrapper>
        <TabGroup tabs={tabsHeaders}>
          {about && <AboutProduct>{about}</AboutProduct>}
          {nutrition && (
            <TwoColumn gap="gap-10 md:gap-20">
              <TwoColumn.Left>
                <NutritionInfoTable title="Información nutricional">
                  {nutrition}
                </NutritionInfoTable>
              </TwoColumn.Left>

              <TwoColumn.Right className="w-full min-h-[385px] md:min-h-0 relative  empty:hidden" />
            </TwoColumn>
          )}
          {tabsHeaders.includes('Trazabilidad') && (
            <AboutProduct>{trazabilidad}</AboutProduct>
          )}
          {tabsHeaders.includes('Conservación') && (
            <AboutProduct>{preservation}</AboutProduct>
          )}
        </TabGroup>
      </Container>

      <Container wrapper backgroundColor="bg-primary-50">
        <ProductBlockShopify
          shopifyCategory="destacados"
          shopifyProducts={content.views.products}
          title="Te puede interesar"
          cardVariation={Variation.DEFAULT}
        />
      </Container>
    </>
  );
};


